<mat-radio-button
  [disabled]="disable"
  [checked]="checked"
  [disableRipple]="disableRipple"
  [color]="color"
  [label]="label"
  [labelPosition]="labelPosition"
  value="1"
  >{{ label }}</mat-radio-button
>
